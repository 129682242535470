import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { authSelectors } from '@ucheba/store/auth'
import { profileSelectors, profileThunks } from '@ucheba/store/profile'
import { authPhoneSelectors, authPhoneThunks } from '@ucheba/store/auth/phone'
import { profileRegistrationThunks } from '@ucheba/store/profile/registration'
import dayjs from '@ucheba/utils/helpers/date/dayjs'
import {
  authPhoneCodeSelectors,
  authPhoneCodeThunks,
} from '@ucheba/store/auth/phone/code'
import { eventsIdThunks } from '@ucheba/store/events/id'
import { getVisitInfo } from '@ucheba/utils/hooks/useVisitsTracker'
import { UCHEBA_SITE_NAME } from '@ucheba/utils/constants/core'
import { useDialog } from '@ucheba/ui/components/Dialog/bll'
import { eventsMyThunks } from '@ucheba/store/events/my'
import { IDialog } from '@ucheba/ui/components/Dialog/types'
import { EDataKeys } from '@ucheba/utils/types/dataKeys'
import { useRouter } from 'next/router'
import { useAllowedChannels } from '@ucheba/utils/hooks/useAllowedChannels'
import { EMessengerChannel } from '@ucheba/store/profile/types'
import { useNotice } from '@ucheba/ui/components/Notice/bll'
import { INotice } from '@ucheba/ui/components/Notice/types'
import {
  parentFormDialogRequestId,
  parentFormDialogSuccessId,
} from '../../../ParentFormDialog/constants'

interface IFormReg {
  name: string
  phone: string
  isParent: string
  classForm: string
  email: string
}

interface IProps {
  id?: number | null
  onClickRegistration?: () => void

  visitSiteName?: string

  rubricId?: number | null

  /** Например если это попап, то закрыть */
  onAfterSubmitForm?: () => void
}

interface IUseEventBrandingUchebaReg {
  (props: IProps): {
    onSubmit: (values: any) => void
    onPhoneConfirmSubmit: (code: string) => void
    onPhoneConfirmClose: () => void
    phone: string | null | undefined
    needConfirmPhone: boolean
    parentFormDialog: IDialog
    messengersDialog: IDialog
    isLoading: boolean
    isForTutor: boolean
    initialValues: {
      name: string
      phone: string
      isParent: string
      classForm: {
        id: number
        name: string
      }[]
    }
    notAllowedMessengerNotice: INotice
  }
}

const isSummer = (): boolean => {
  const currentMonth = Number(dayjs().format('M'))

  return currentMonth > 5 && currentMonth < 9
}

const transferGrade = (gradeOrYear: string): string => {
  const currentYear = Number(dayjs().format('YYYY'))
  const currentMonth = Number(dayjs().format('M'))
  const gradeOrYearNumber = Number(gradeOrYear)

  if (isSummer()) {
    return String(currentYear - gradeOrYearNumber)
  }

  return String(currentYear - gradeOrYearNumber + (currentMonth > 5 ? 1 : 0))
}

export const useEventBrandingUchebaReg: IUseEventBrandingUchebaReg = (props) => {
  const { id, onAfterSubmitForm, visitSiteName = UCHEBA_SITE_NAME, rubricId } = props
  const dispatch = useDispatch()
  const router = useRouter()
  const { query } = router

  const isAuth = useSelector(authSelectors.isAuth)
  const profile = useSelector(profileSelectors.entity)
  const store = useStore()
  const parentFormDialog = useDialog(parentFormDialogRequestId + id)
  const messengersDialog = useDialog(parentFormDialogSuccessId + id)
  const notAllowedMessengerNotice = useNotice(`notAllowedMessengerNoticeId${id}`)

  const isAllowedMess = useAllowedChannels([
    EMessengerChannel.vk_ucheba,
    EMessengerChannel.telegram_ucheba,
  ])

  // Если мероприятие для репетитора
  const isForTutor = useMemo(() => {
    return !!(rubricId && rubricId === 5)
  }, [rubricId])

  const initialValues = useMemo(() => {
    return {
      name: '',
      phone: '',
      email: profile?.email || null,
      isParent: 'false',
      classForm: [
        { id: 1, name: '9' },
        { id: 2, name: '10' },
        { id: 3, name: '11' },
      ],
    }
  }, [profile?.email])

  const [currentFormValues, setCurrentFormValues] = useState<IFormReg | null>(null)

  const [needConfirmPhone, setNeedConfirmPhone] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const patchProfile = useCallback(
    async (values: IFormReg): Promise<any> => {
      const educations =
        values.classForm > '0'
          ? [
              {
                programType: 1000,
                fromYear: Number(transferGrade(values.classForm)),
              },
            ]
          : []

      await dispatch<any>(
        profileThunks.patch({
          data: {
            name: values.name,
            email: values.email,
            isParent: values?.isParent === 'true',
            educations,
            enrolleeInfo: {
              educationLevel: values.classForm < '0' ? 2 : 1,
            },
          },
        })
      )

      await dispatch<any>(profileThunks.get({}))
      return new Promise<void>((res) => {
        setTimeout(() => {
          res()
        }, 10)
      })
    },
    [dispatch]
  )

  const registerProfile = useCallback(
    async (values: IFormReg, phoneCode: string) => {
      const educations =
        values.classForm > '0'
          ? [
              {
                programType: 1000,
                fromYear: Number(transferGrade(values.classForm)),
              },
            ]
          : []

      const visit = getVisitInfo(visitSiteName)

      const reqData = {
        name: values?.name,
        phone: values?.phone,
        email: values.email,
        phoneCode,
        isParent: values?.isParent === 'true',
        educations,
        enrolleeInfo: {
          educationLevel: values.classForm < '0' ? 2 : 1,
        },
        visit,
      }

      if (rubricId && rubricId === 5) {
        delete reqData.educations
        delete reqData.isParent
        delete reqData.enrolleeInfo
      }

      if (query[EDataKeys.invitationKey]) {
        reqData.inviteCode = query[EDataKeys.invitationKey]
      }

      await dispatch<any>(
        profileRegistrationThunks.fetch({
          data: reqData,
        })
      )
      await dispatch<any>(profileThunks.get({}))
    },
    [dispatch, query, visitSiteName, rubricId]
  )

  const sendRequestCode = useCallback(
    async (values: any, code: string | null): Promise<any> => {
      setIsLoading(true)

      const reqData = {
        phone: values.phone,
        code,
        rememberMe: true,
      }

      if (query[EDataKeys.invitationKey]) {
        reqData.inviteCode = query[EDataKeys.invitationKey]
      }

      const res = await dispatch<any>(
        authPhoneThunks.phone({
          data: reqData,
        })
      )

      setIsLoading(false)

      return res
    },
    [dispatch, query]
  )

  const register = useCallback(async () => {
    const profileStore = store.getState().profile.entity

    await dispatch(
      eventsIdThunks.register({ data: { id, visit: getVisitInfo(visitSiteName) } })
    )

    if (profileStore && !profileStore.secondPhone && rubricId && rubricId !== 5) {
      setTimeout(() => {
        parentFormDialog.openDialog()
      }, 600)
    } else {
      setTimeout(() => {
        messengersDialog.openDialog()
      }, 600)
    }
    if (onAfterSubmitForm) {
      onAfterSubmitForm()
    }

    await dispatch(eventsMyThunks.fetch({}))

    if (!isAllowedMess) {
      notAllowedMessengerNotice.addNotice()
    }
  }, [
    rubricId,
    store,
    id,
    onAfterSubmitForm,
    isAllowedMess,
    parentFormDialog,
    messengersDialog,
    notAllowedMessengerNotice,
    visitSiteName,
  ])

  const onSubmit = useCallback(
    async (values) => {
      setCurrentFormValues(values)

      const formPhone = values.phone.replace(/[^\d]/g, '')
      const profilePhone = profile?.phone?.replace(/[^\d]/g, '')

      if (!isAuth || (profilePhone && profilePhone !== formPhone)) {
        await dispatch(authPhoneCodeThunks.sendCode({ data: { phone: values.phone } }))

        const error = authPhoneCodeSelectors.error(store.getState())

        if (!error) {
          setNeedConfirmPhone(true)
        }
      } else {
        await patchProfile(values)
        await register()
      }
    },
    [isAuth, patchProfile, profile?.phone, store]
  )

  const onPhoneConfirmSubmit = useCallback(
    async (code: string) => {
      const res = await sendRequestCode(currentFormValues, code)

      const error = authPhoneSelectors.error(store.getState())

      if (!currentFormValues) return

      if (!error) {
        if (res.payload) {
          await patchProfile(currentFormValues)
        } else {
          await registerProfile(currentFormValues, code)
        }
        setNeedConfirmPhone(false)
        await register()
      }
    },
    [sendRequestCode, currentFormValues, store, register, patchProfile, registerProfile]
  )

  const onPhoneConfirmClose = useCallback(() => {
    setNeedConfirmPhone(false)
  }, [setNeedConfirmPhone])

  return {
    initialValues,
    onSubmit,
    onPhoneConfirmSubmit,
    phone: currentFormValues?.phone,
    needConfirmPhone,
    isLoading,
    onPhoneConfirmClose,
    parentFormDialog,
    messengersDialog,
    notAllowedMessengerNotice,
    isForTutor,
  }
}
